<!-- eslint-disable no-unused-vars -->
<script setup> 
import { ref, computed, onMounted, onUnmounted } from 'vue'

// ====== refs ======
const color = ref({
  priGreen: '#5D7271',
  priBlue: '#4D6578',
  priGrey: '#223237',
  
  secRed: '#893A47',
  secYellow: '#E6A820',
  secLightYellow: '#E0F09C',
  secLightGrey: '#BDBDBD',

  offBlack: '#231F20',
  offWhite: '#EEEEEE',
})

const mode = ref('')

// ====== computed ======
const currentView = computed(() => {
  const mobileView = {
    flexDirection: 'column',
    height: 'min(100vw, 30rem)',
    fontSize: 'max(3vw, 1rem)',
    width: '75vw',
  }
  const desktopView = {
    flexDirection: 'row',
    height: '75vh',
    fontSize: '2vw',
    width: '50vw',
  }

  return mode.value === 'mobile' ? mobileView : desktopView
})

const windowSize = ref([0, 0])

// ====== lifecycle ======
onMounted(() => {
  window.addEventListener('resize', updateView)
  updateView()
})
onUnmounted(() => {
  window.removeEventListener('resize', updateView)
})

// ====== custom ======
const updateView = () => {
  mode.value = window.innerWidth <= 768 ? 'mobile' : 'desktop'
  windowSize.value = [window.innerWidth, window.innerHeight]
}
</script>

<template>
  <div id="site">
    <div id="jumbo">
      <img class="seal" src='../assets/seal.svg' />
      <div class="jumboLinks">
        <div><a href='https://www.youtube.com/channel/UCnUJM8rj5HiPEsdBkJOs9nw' target='_blank' class="socials">YOUTUBE</a></div>
        <div><a href='https://www.instagram.com/_peakfilms/' target='_blank' class="socials">INSTAGRAM</a></div>
        <div><a href='https://www.facebook.com/peakfilms/' target='_blank' class="socials">FACEBOOK</a></div>
      </div>
      <img class="logo" src='../assets/logo_title.svg' />
    </div>
    <div id="about">
      <img class="aboutImage" src="../assets/about.jpg" />
      <div class="synopsis">Jesteśmy z gór, uwielbiamy przeżywać emocje związane ze zdobywaniem szczytów. Ta sama idea towarzyszy nam podczas kręcenia filmów. Nieważne czy mamy do czynienia z krótkim klipem reklamowym, teledyskiem, zdjęciami z drona, czy długim filmem dokumentalnym - chcemy, żeby każde zlecenie było dla nas wyzwaniem. Dlatego ciągle inwestujemy w sprzęt i nowe rozwiązania – wierzymy, że zawsze znajdzie się kolejny szczyt do zdobycia.</div>
    </div>
    <div id="work">
      <h1>REALIZACJE</h1>
      <div class="workMovies">
        <div>
          <h2>FILMY DOKUMENTALNE</h2>
          <iframe class="video" src="https://www.youtube.com/embed/cUvl3wbrGEY?si=rrMtEdYxLK4FD70L" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen showinfo='0'></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/_UUPx79ea1A?si=QG19kSkPiHc6YNAH" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/GxHKMNZTkHQ?si=QVKNcfYxSRJ7Kvk_" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div>
          <h2>RELACJE Z IMPREZ</h2>
          <iframe class="video" src="https://www.youtube.com/embed/THf5P7CquTI?si=R7R-wCU_JachNYF0" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/Hn4mdRDx9GY?si=yqH16wEk70RFw0pq" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/opQoeuI7tBc?si=2aq_BOJEw71oZNzJ" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div>
          <h2>SPOTY REKLAMOWE</h2>
          <iframe class="video" src="https://www.youtube.com/embed/0MwVEdVROok?si=RNzeEJDPE1xjkaau" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/reBlUAcHR3M?si=3mXJdqho2p_NnpiR" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe class="video" src="https://www.youtube.com/embed/PFY1cn3zbwE?si=vbWs7asDX1oExJXA" title="YouTube video player" frameborder="0" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
    <div id="clients">
      <h1 class="clientsTitle">ZAUFALI NAM</h1>
      <div class="clientsLogos">
        <a class="clientsLogo" href="https://browarcieszyn.pl/" target="_blank"><img src="../assets/logos/01-browar.a9047303.png" /></a>
        <a class="clientsLogo" href="https://sklep.piwoteka.pl/noszak" target="_blank"><img src="../assets/logos/02-noszak.723d573f.png" /></a>
        <a class="clientsLogo" href="https://tkb.art.pl/" target="_blank"><img src="../assets/logos/03-tkb.4756c6a3.png" /></a>
        <a class="clientsLogo" href="https://ockorawa.pl/" target="_blank"><img src="../assets/logos/04-orawskie.9601e83d.png" /></a>
        <a class="clientsLogo" href="https://ustron.pl/pl" target="_blank"><img src="../assets/logos/05-ustron.f453f22f.png" /></a>
        <a class="clientsLogo" href="https://mdk.ustron.pl/" target="_blank"><img src="../assets/logos/06-prazakowka.e72c2d94.png" /></a>
        <a class="clientsLogo" href="http://www.poludnieevents.pl/" target="_blank"><img src="../assets/logos/07-polodnie-events.828b5130.png" /></a>
        <a class="clientsLogo" href="https://skolnity.pl/" target="_blank"><img src="../assets/logos/08-skolnity.daf29dfe.png" /></a>
        <a class="clientsLogo" href="https://rdzawediamenty.pl/" target="_blank"><img src="../assets/logos/09-rdzawe.7fdc02ae.png" /></a>
        <a class="clientsLogo" href="https://www.instagram.com/pracownia.tattoo/" target="_blank"><img src="../assets/logos/10-tatuaze.f44d544a.png" /></a>
        <a class="clientsLogo" href="https://www.wisla.pl/" target="_blank"><img src="../assets/logos/11-wisla.865ef6ed.png" /></a>
        <a class="clientsLogo" href="https://www.wisla.pl/mieszkaniec/wislanskie-centrum-kultury" target="_blank"><img src="../assets/logos/12-wck.103224e8.png" /></a>
        <a class="clientsLogo" href="https://www.cieszyn.pl/" target="_blank"><img src="../assets/logos/13-cieszyn.09a1c3b6.png" /></a>
        <a class="clientsLogo" href="https://www.szczyrk.pl/" target="_blank"><img src="../assets/logos/14-szczyrk.e0ed854e.png" /></a>
        <a class="clientsLogo" href="https://www.brenna.org.pl/" target="_blank"><img src="../assets/logos/15-brenna.e86a76c0.png" /></a>
        <a class="clientsLogo" href="https://gastromix.pl/" target="_blank"><img src="../assets/logos/16-gastromix.e6e39456.png" /></a>
        <a class="clientsLogo" href="https://www.facebook.com/issatourholiday/" target="_blank"><img src="../assets/logos/17-issatour.3c4b1b0d.png" /></a>
      </div>
    </div>
  </div>
  <!-- <div id="debug">
    {{ mode }} <br>
    {{ JSON.stringify(currentView) }} <br>
    {{ windowSize }}
  </div> -->
</template>

<style lang="scss">
@font-face {
  font-family: 'Acumin';
  src: url('../assets/Acumin-RPro.otf');
}

@font-face {
  font-family: 'Acumin Bold';
  src: url('../assets/Acumin-BdPro.otf');
}

body {
  margin: 0;
  font-family: 'Acumin', Verdana, sans-serif;

  background-color: #223237;
}

h1 {
  margin-top: 5rem;
}

#site {
  display: flex;
  flex-direction: column;
}

#jumbo {
  background-color: v-bind('color.priGrey');
  background-image: url('../assets/bg.jpg');
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;

  width: calc(100vw - (100vw - 100%));
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.seal {
  width: 3rem;
  color: green;
  margin: 2rem;
}

.jumboLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  font-family: 'Acumin Bold', Verdana, sans-serif;

  width: 80%;
}

.socials {
  color: v-bind('color.secYellow');
  filter: drop-shadow(0px 0px 4px #000);
  text-decoration: none;
}

.logo {
  width: 20rem;
  margin: 2rem;
}

#about {
  display: flex;
  flex-direction: v-bind('currentView.flexDirection');
  align-items: center;
  justify-content: space-around;

  width: calc(100vw - (100vw - 100%));
  height: 100vh;

  background-color: v-bind('color.priGrey');
  color: v-bind('color.offWhite');
}

.aboutImage {
  height: v-bind('currentView.height');
}

.synopsis {
  width: v-bind('currentView.width');

  text-align: justify;
  font-size: v-bind('currentView.fontSize');
  color: v-bind('color.secYellow')
}

#work {
  background-color: v-bind('color.priGrey');
  color: v-bind('color.secYellow');
  text-align: center;
}

.workMovies {
  display: flex;
  flex-direction: v-bind('currentView.flexDirection');
}

iframe {
  width: 90%;
  aspect-ratio: 21 / 9;
}

#clients {
  background-color: v-bind('color.priGrey');
}

.clientsTitle {
  text-align: center;
  color: v-bind('color.secYellow')
}

.clientsLogos {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  margin-bottom: 3rem;
}

.clientsLogo {
  display: flex;
  flex-basis: 11%;

  @media screen and ( max-width: 1600px) {
    flex-basis: 16%;
  }

  @media screen and ( max-width: 900px) {
    flex-basis: 20%;
  }

  

  img {
    width: 9rem;
    margin: 0 auto;
  }
}

#debug {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: green;
  color: white;
  padding: 0.4rem;
}
</style>
